.bg-tree {
    background-color: #06100;
    background-image: url('../images/sapin-optimized.webp');
    background-position: center;
    background-size: cover;
}

.stroke-diagonal {
    position: relative;
}

.stroke-diagonal::after {
    @apply bg-red;
    position: absolute;
    content: " ";
    height: 4px;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-7deg);
}

.my-node-exit {
    transform: translateY(-100vh);
    transition: transform 1000ms;
}
.my-node-exit-active {
    transform: translateY(-100vh);
    transition: transform 1000ms;
}

.my-node-exit-done {
    transform: translateY(-100vh);
    transition: transform 1000ms;
}

.generator {
    opacity: 0;
}

.generator-enter {
    opacity: 1;
}

.phrase-generator-container > div {
    position: absolute;
}